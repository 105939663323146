:root {
    --main-color: #ffffff;
    --main-bg-color: rgba(0, 0, 0, 0.75);
}

html body {
    background-image: url("https://cdn.i-scmp.com/sites/default/files/d8/images/canvas/2023/03/22/bf3a12a3-6c96-4f96-87ca-b51dc92aa48d_af203a08.jpg");
    background-color: rgb(29, 33, 44);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    background-attachment: fixed;
    color: var(--main-color);
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: center;
}

@media only screen and (max-width: 1400px) {
    html body {
        background-size: auto 100%;
        background-position: 68% 0;
    }

    .head.wrap-background {
        font-size: calc(30px * 0.9);
    }
}

.centered-container {
    width: 90%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.wrap-background {
    display: inline;
    background-color: var(--main-bg-color);
    padding: 0.2em;
    border: rgba(128, 128, 128, 0.205);
    border-style: solid;
}

.search-results {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;
}

.snip1579 {
    font-family: 'Lato', Arial, sans-serif;
    position: relative;
    display: inline-block;
    overflow: hidden;
    margin: 8px;
    min-width: 250px;
    max-width: 400px;
    max-height: 400px;
    min-height: 250px;
    background-color: transparent;
    color: var(--main-color);
    font-size: 16px;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.15);
}


.snip1579 *,
.snip1579 img {
    -webkit-transition: all 0.35s;
    transition: all 0.35s;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    color: var(--main-color);
}

.snip1579 img {
    max-width: 100%;
    vertical-align: top;
}

.why {
    color:red;
    background-color: transparent;
}

.whyNot {
    color: rgb(60, 255, 0);
    background-color: transparent;
}
.whyNotUnknown {
    color: white;
    background-color: transparent;
}
.snip1579 figcaption,
.snip1579 figcaption:hover {
    position: absolute;
    height: 0px;
    left: 15px;
    right: 15px;
    bottom: 15px;
    overflow: hidden;
    padding: 5px;
    background-color: var(--main-bg-color);
    color: white;
    padding: 5px 5px 25px 5px;
     text-align: center;
}

.card-sponsor {
    color: red;
}

h1, h2,
.snip1579 h1,
.snip1579 h2 {
    text-align: center;
    color: white;
}

h1:link,
.snip1579 h1:link,
.snip1579 h2:link {
    color: white;
    background-color: transparent;
}

.snip1579 h3 {
    font-family: 'Oswald';
    text-transform: uppercase;
    font-size: 20px;
    font-weight: 400;
    line-height: 24px;
    margin: 3px 0;
    text-align: center;
    background-color: transparent;
    color: var(--main-color);
}

.snip1579 h5 {
    font-weight: 400;
    margin: 0;
    text-transform: uppercase;
    letter-spacing: 1px;
}

.snip1579 blockquote {
    padding: 0;
    margin: 0;
    font-style: italic;
    font-size: 1em;
}

.snip1579 a {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    border-radius: 5%;
}

.snip1579:hover figcaption,
.snip1579.hover figcaption {
    height: calc(90%);
}

.App {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 2em;
}

.head {
    font-size: 40px;
    font-family: sans-serif;
    margin-top: 5px;
    margin-bottom: 15px;
    padding-top: 3px;
    color: var(--main-color);
    text-decoration-color: #0f0e0e;
    text-align: center;
    background-color: var(--main-bg-color);
    padding-bottom: 3px;
}
.minor {
    font-size: 30px;
    font-family: sans-serif;
    margin-top: 5px;
    margin-bottom: 0px;
    padding-top: 3px;
    color: var(--main-color);
    text-decoration-color: #0f0e0e;
    text-align: center;
    background-color: var(--main-bg-color);
    padding-bottom: 3px;
}

.mid {
    font-size: 20px;
    font-family: sans-serif;
    margin: 5px auto;
    padding-top: 5px;
    color: var(--main-color);
    text-decoration-color: #0f0e0e;
    text-align: center;
    width: 320px;
    background-color: var(--main-bg-color);
    display: block;
}

.bodies {
    font-size: 30px;
    font-family: 'Orbitron', sans-serif;
    margin-top: 10px;
    padding-top: 30px;
    text-shadow: 2px 2px rgb(255, 255, 255);
    color: rgb(0, 0, 0);
    background-color: rgb(7, 6, 6);
    background: #000000;
}

.imageHead {
    width: 150px;
    margin-bottom: -85px;
    padding-bottom: 0px;
}

.search {
    background-color: rgba(214, 21, 14, 0.87);
}

.boxes {
    height: 350px;
    width: 300px;
    border-radius: 5%;
}

.split {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    width: 100%;
}

.episode {
    justify-content: center;
    width: 100%;
    text-align: center;
}

.right {
    display: flex;
    float: right;
}

.status {
    float: right;
}

.footer {
    position: fixed;
    bottom: 0;
    width: 100%;
    padding: 0.1rem;
    font: 0.8em sans-serif;
    background-color: rgba(10, 0, 0, 0.8);
    color: var(--main-color);
    text-align: center;
    font-size: 0.5em;
}

.footer ul {
    columns: 3;
}

.link {
    position: fixed;
    bottom: 0;
    bottom: 10px;
    color: white;
    text-decoration: none;
}

link:link {
    color: white;
}

/* mouse over link */
link:hover {
    color: white;
}
